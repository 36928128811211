import { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosInstanceService } from "../../../data/axiosInstance/axiosInstance";
import AuthServiceSSO from "../../jwt/auth.serviceSSO";

import { generateCodeVerifier } from "../../../store/app/pkce/PkceSlice";
import useEffectOnChange  from "../../hooks/CustomHooks";
import { fetchUserData, setConfigLanguage } from "../../../store/app/ticket/TicketSlice";

const FormLoginSSO = () => {
  const params = new URLSearchParams(window.location.search);

  const APISICUREZZAKEYCLOCK = `${process.env.REACT_APP_API_OVERX_IDP_URL}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState("");

  const codeVerifier = useSelector((state) => state?.pkceReducer?.codeVerifier);
  const codeChallenge = useSelector((state) => state?.pkceReducer?.codeChallenge);

  useEffect(() => {
    if (params.get("code")) {
      setCode(params.get("code"));
    } else {
      dispatch(generateCodeVerifier());
    }
  }, []);

  useEffectOnChange(() => {
    window.location.replace(
      `${APISICUREZZAKEYCLOCK}/auth/realms/revo/protocol/openid-connect/auth?client_id=revo-access&response_type=code&scope=openid&redirect_uri=${ 
        window.location.href 
        }&code_challenge=${ 
        codeChallenge 
        }&code_challenge_method=S256`
    );
  }, [codeChallenge]);

  useEffectOnChange(() => {
    AuthServiceSSO.login(params.get("code"), codeVerifier).then((responsed) => {
      localStorage.setItem("user", JSON.stringify(responsed));
      axiosInstanceService.setToken();
      if (responsed.access_token) {
        const decodedPayload = jwtDecode(responsed.access_token);
        navigate("/home");
        dispatch(fetchUserData(decodedPayload?.email?.toLowerCase()));
        dispatch(setConfigLanguage(decodedPayload));
      } else {
        localStorage.setItem("message", "400");
      }
    });
  }, [code]);
};

export default FormLoginSSO;