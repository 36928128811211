import React, { useState, useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Toast, ToastBody, Button } from "reactstrap"
import { checkTotalItemMessageToast ,removeTotalItemMessageToast } from '../../store/app/ws/WsSlice';
import ToastContent from './ToastContent';

const ToastContainer = () => {
  const dispatch = useDispatch();
  //const [messageHistory, setMessageHistory] = useState([]);

  const WS_URL = `${process.env.REACT_APP_WS_SINISTRI_URL}`;  
  const [socketUrl] = useState(WS_URL);
  
  const { lastMessage, readyState } = useWebSocket(socketUrl);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  const handleRemoveTaskNavTab = (reqId) => {
    dispatch(removeTotalItemMessageToast(reqId)); //AddNavTaskReducer  
  }

  useEffect(() => {
    if (lastMessage !== null) {
      //setMessageHistory((prev) => prev.concat(lastMessage));
      //console.log(messageHistory);
      //proviamo ad alleggerire l'oggetto per non far sudare redux
      const lastMessageLight = `{"requestId":"${JSON.parse(lastMessage.data)?.requestId}", "operation":{"status":"${JSON.parse(lastMessage.data)?.operation?.status}", "statusCode": "${JSON.parse(lastMessage.data)?.operation?.statusCode}"}}`;
      const dataLastMessageLight = { "data" : lastMessageLight}
      dispatch(checkTotalItemMessageToast(dataLastMessageLight));
    }
  }, [lastMessage]);

  const totalRequestMessage = useSelector((state) => state.wsReducer.itemMessages);
  //console.log("totalRequestMessage",totalRequestMessage);

  return (    
    <div className="toast_container position-fixed z-5 bottom-0 start-0 ms-4 mb-4">
    
        {totalRequestMessage.map((message) => (
          <Toast className="d-flex bg-dark text-bg-dark align-items-top border-0 mb-2 p-2 shadow-lg" key={JSON.parse(message).requestId}>
            <ToastBody key = {crypto.randomUUID()}>
              <ToastContent key={JSON.parse(message).requestId} message={message}/>
            </ToastBody>
            <Button close className="btn-close-white mt-2 ms-auto me-2"  aria-label="Chiudi" onClick={() => handleRemoveTaskNavTab(JSON.parse(message).requestId)} key={crypto.randomUUID()}/>
          </Toast>
        ))}
          <span>The WebSocket is currently <b>{connectionStatus}</b></span> 
    </div>
  )
}

export default ToastContainer;
