import axiosInstance from 'axios';

const API_URL = `${process.env.REACT_APP_API_OVERX_URL}/api/v1/sicurezza/user/info`;

const getPublicContent = () => {
  return axiosInstance.get(API_URL);
};

const getUserBoard = () => {
  //return axios.get(API_URL + "", { headers: authHeader() });
  return axiosInstance.get(API_URL);
};

const getModeratorBoard = () => {
   //return axios.get(API_URL + "", { headers: authHeader() });
   return axiosInstance.get(API_URL);
};

const getAdminBoard = () => {
   //return axios.get(API_URL + "", { headers: authHeader() });
   return axiosInstance.get(API_URL);
};
 
//TODO:shit temporary code only for espain
const translateFlusso = (flussoName) => {
    return "NoFlusso".replace(flussoName,"");
}
 
// eslint-disable-next-line no-unused-vars
const translatesubId = (subIdName,idFlusso) => {
/*
  if (subIdName==="Sinistro" && idFlusso === "12") subIdName = "Siniestro"; 

  if (subIdName==="Causa" && idFlusso=== "12") subIdName = "Demanda"; 

  if (subIdName==="Incarico" && idFlusso=== "12") subIdName = "Asignacion"; 

  if (subIdName==="Recupero" && idFlusso=== "12") subIdName = "Recuperacion"; 

  if (subIdName==="Autorizzazione per diniego" && idFlusso=== "12") subIdName = "Autorizacion de denegacion"; 

  if (subIdName==="Validazione pagamento spese" && idFlusso=== "12") subIdName = "Validacion del pago de gastos"; 

  if (subIdName==="Validazione pagamento indennizzo" && idFlusso=== "12") subIdName = "Validacion del pago de la indemnizacion"; 

  if (subIdName==="Validazione riserva spese" && idFlusso=== "12") subIdName = "Validacion de la reserva de gastos"; 

  if (subIdName==="Validazione riserva indennizzo" && idFlusso=== "12") subIdName = "Validacion de la reserva de indemnizacion"; 

  if(subIdName === "Pagamento indennizzo" && idFlusso=== "12") subIdName = "Pago de indemnizacion";

  if(subIdName === "Pagamento spese" && idFlusso=== "12") subIdName = "Pago de gastos";
*/
 return subIdName;
}

const translateCriterioByLanguage = (criterio,language) => {
  
  let name = criterio;

    if (criterio==="denuncia" && language === "es") name = "denuncias"; 
    if (criterio==="denuncias" && language === "it") name = "denuncia";

    if (criterio==="istruttoria" && language === "es") name = "investigacion"; 
    if (criterio==="investigacion" && language === "it") name = "istruttoria";

    if (criterio==="sinistro" && language === "es") name = "siniestro"; 
    if (criterio==="siniestro" && language === "it") name = "sinistro"; 
  
    if (criterio==="causa" && language=== "es") name = "demanda"; 
    if (criterio==="demanda" && language=== "it") name = "causa"; 
  
    if (criterio==="incarico" && language=== "es") name = "asignacion"; 
    if (criterio==="asignacion" && language=== "it") name = "incarico"; 
  
    if (criterio==="recupero" && language=== "es") name = "recuperacion"; 
    if (criterio==="recuperacion" && language=== "it") name = "recupero"; 
  
    if (criterio==="autorizzazione per diniego" && language=== "es") name = "Autorizacion de denegacion"; 
  
    if (criterio==="validazione pagamento spese" && language=== "es") name = "Validacion del pago de gastos"; 
  
    if (criterio==="validazione pagamento indennizzo" && language=== "es") name = "Validacion del pago de la indemnizacion"; 
  
    if (criterio==="validazione riserva spese" && language=== "es") name = "Validacion de la reserva de gastos"; 
  
    if (criterio==="validazione riserva indennizzo" && language=== "es") name = "Validacion de la reserva de indemnizacion"; 
  
    if(criterio === "pagamento indennizzo" && language=== "es") name = "Pago de indemnizacion";
  
    if(criterio === "pagamento spese" && language=== "es") name = "Pago de gastos";
    if(criterio === "Pago de gastos" && language=== "it") name = "pagamento spese" ;
  
   return (name === undefined) ? '' : name.toUpperCase(); 
  }
 


const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  translateFlusso,
  translatesubId,
  translateCriterioByLanguage
};

export default UserService;

// [N.B.: THIS SERVICE IS NOT USED, WE WILL USE T FOR ANOTHER WAY]


