import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  totaleRiservaLorda: {
    loader: true
  },
  totaleRiservaNetta: {
    loader: true
  },
  totatalePagamenti:{
    loader: true
  },
  totaleRiservaResidua: {
    loader: true
  },
  totaleRiservaAttiva: {
    loader: true
  },
  totaleRecuperi: {
    loader: true
  },
  totaleRiservaAttivaResidua: {
    loader: true
  },
  franchigia: {
    loader: true
  },
  totaleMovimenti: [],
  riserve: [],
  pagamenti: []
};

export const FinancialsSlice = createSlice({
  name: 'financials',
  initialState,
  reducers: {
    getTotaleRiservaLorda: (state, action) => {
      //state.allegati = [];
      state.totaleRiservaLorda = action.payload;
    },
    getTotaleRiservaNetta: (state, action) => {
      //state.allegati = [];
      state.totaleRiservaNetta = action.payload;
    },
    getTotatalePagamenti: (state, action) => {
      //state.allegati = [];
      state.totatalePagamenti = action.payload;
    },
    getTotataleRiservaResidua: (state, action) => {
      //state.allegati = [];
      state.totaleRiservaResidua = action.payload;
    },
    getTotaleRiservaAttiva: (state, action) => {
      //state.allegati = [];
      state.totaleRiservaAttiva = action.payload;
    },
    getTotaleRecuperi: (state, action) => {
      //state.allegati = [];
      state.totaleRecuperi = action.payload;
    },
    getTotaleRiservaAttivaResidua: (state, action) => {
      //state.allegati = [];
      state.totaleRiservaAttivaResidua = action.payload;
    },
    getFranchigia: (state, action) => {
      //state.allegati = [];
      state.franchigia = action.payload;
    },
    setFinancialsTotaleMovimenti: (state, action) => {
      //state.allegati = [];
      state.totaleMovimenti = action.payload;
    },
    setFinancialsRiserve: (state, action) => {
      //state.allegati = [];
      state.riserve = action.payload;
    },
    setFinancialsPagamenti: (state, action) => {
      //state.allegati = [];
      state.pagamenti = action.payload;
    },
    setLoader: (state, action) => {
      state.totaleRiservaLorda.loader = action.payload;
      state.totaleRiservaNetta.loader = action.payload;
      state.totatalePagamenti.loader = action.payload;
      state.totaleRiservaResidua.loader = action.payload;
      state.totaleRiservaAttiva.loader = action.payload;
      state.totaleRecuperi.loader = action.payload;
      state.totaleRiservaAttivaResidua.loader = action.payload;
      state.franchigia.loader = action.payload;
    }
  }
});

export const { 
  getTotaleRiservaLorda,
  getTotaleRiservaNetta, 
  getTotatalePagamenti, 
  getTotataleRiservaResidua, 
  getTotaleRiservaAttiva, 
  getTotaleRecuperi, 
  getTotaleRiservaAttivaResidua, 
  getFranchigia,
  setFinancialsTotaleMovimenti,
  setFinancialsRiserve,
  setFinancialsPagamenti,
  setLoader
} = FinancialsSlice.actions;

export const fetchTotaleRiservaLorda = (idSinistro, datiForm) =>  (dispatch) => {   
  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRiservaLorda/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID  } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error) {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = true
      dispatch(getTotaleRiservaLorda(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotaleRiservaLorda(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  
  /* return dispatch(getTotaleRiservaLorda({
    "totaleRiservaLordaIndennizzo": "1.000,00",
    "totaleRiservaSpese": "0,00",
    loader: false
  })); */
}; 

export const fetchTotaleRiservNetta = (idSinistro, datiForm) => async (dispatch) => {   
  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
   
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRiservaNetta/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID  } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('error ',res.error); 
      newData.loader = true
      dispatch(getTotaleRiservaNetta(newData));
    }else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotaleRiservaNetta(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
   
  /* return dispatch(getTotaleRiservaNetta({
    "totaleRiservaNettaIndennizzo": "00,00",
    "totaleRiservaSpese": "0,00",
    loader: false
  })); */
}; 

/**
 * chimata per popolare box totalePagamenti
 * @param {uuID} idSinistro id del sinistro (uuid) 
 * @returns popolo getTotatalePagamenti per popolare i box financial
 */
export const fetchTotatalePagamenti= (idSinistro, datiForm) => async (dispatch) => {   

  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totalePagamenti/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID  } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('error',res);
      newData.loader = true
      dispatch(getTotatalePagamenti(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotatalePagamenti(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  /* return dispatch(getTotatalePagamenti({
    "totalePagamentoIndennizzo": "00,00",
    "totalePagamentoSpese": "0,00",
    loader: false
  })); */
};

export const fetchTotataleRiservaResidua = (idSinistro, datiForm) => async (dispatch) => {   
  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRiservaResidua/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID  } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = true
      dispatch(getTotataleRiservaResidua(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotataleRiservaResidua(newData));
    }
    
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  /* return dispatch(getTotataleRiservaResidua({
    "totaleRiservaResiduaIndennizzo": "00,00",
    "totaleRiservaResiduaSpese": "0,00",
    loader: false
  })); */
};

export const fetchTotataleRiservaAttiva = (idSinistro, datiForm) => async (dispatch) => {   

  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRiservaAttiva/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('error',res);
      newData.loader = true
      dispatch(getTotaleRiservaAttiva(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotaleRiservaAttiva(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  /* return dispatch(getTotaleRiservaAttiva({
    "totaleRiservaAttivaIndennizzo": "0,00",
    "totaleRiservaAttivaSpese": "0,00",
    loader: false,
  })); */
};

export const fetchTotaleRecuperi= (idSinistro, datiForm) => async (dispatch) => {
  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRecupero/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID } })
    .then((result) => result.json())
    .then((res) => {
      if(res.error){
        const newData = JSON.parse(JSON.stringify(res));
        console.log('error',res);
        newData.loader = true
        dispatch(getTotaleRecuperi(newData));
      } else {
        const newData = JSON.parse(JSON.stringify(res));
        console.log('res',res); 
        newData.loader = false
        dispatch(getTotaleRecuperi(newData));
      }
    })
    .catch((err) => {
      console.error('Errore nella chiamata:', err);
      throw err;
    });
    /* return dispatch(getTotaleRecuperi({
      "totaleRecuperiIndennizzo" : "0,00",
      "totaleRecuperiSpese": "0,00",
      loader: false
    })); */
};

export const fetchTotaleRiservaAttivaResidua = (idSinistro, datiForm) => async (dispatch) => {   

  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleRiservaAttivaResidua/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID  } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('error',res);
      newData.loader = true
      dispatch(getTotaleRiservaAttivaResidua(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getTotaleRiservaAttivaResidua(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  /* return dispatch(getTotaleRiservaAttivaResidua({
    "totaleRiservaAttivaResiduaIndennizzo": "0,00",
    "totaleRiservaAttivaResiduaSpese": "0,00",
    loader: false
  })); */
};

export const fetchTotaleFranchigia = (idSinistro, datiForm) => async (dispatch) => {   

  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem('user'));
  const API_URI_CALL = `${API_URL}/api/v1/bff/financials/totaleFranchigia/${idSinistro}?valuta=${datiForm?.valuta}&tassoCambio=${datiForm?.tassoCambio}`;
   

  fetch(API_URI_CALL, { headers: { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID } })
  .then((result) => result.json())
  .then((res) => {
    if(res.error){
      const newData = JSON.parse(JSON.stringify(res));
      console.log('error',res);
      newData.loader = true
      dispatch(getFranchigia(newData));
    } else {
      const newData = JSON.parse(JSON.stringify(res));
      console.log('res',res); 
      newData.loader = false
      dispatch(getFranchigia(newData));
    }
  })
  .catch((err) => {
    console.error('Errore nella chiamata:', err);
    throw err;
  });
  /* return dispatch(getFranchigia({
    "franchigiaPolizza": "2.000,00",
    "recuperoFranchigia": "0,00",
    "residuoFranchigia": "0,00",
    loader: false
  })); */
};

export const fetchFinancialsTotaleMovimenti = (sinistroId) => async (dispatch) => {
  if (sinistroId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/financials/listaMovimenti/${sinistroId}/?filter=ALL`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res', res);
    console.log('err', err);
    if(res){
      return dispatch(setFinancialsTotaleMovimenti(res.data));
    }
  }
  return null;
};

export const fetchFinancialsRiserve = (sinistroId) => async (dispatch) => {
  if (sinistroId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/financials/listaMovimenti/${sinistroId}/?filter=RISERVA`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res', res);
    console.log('err', err);
    if(res){
      return dispatch(setFinancialsRiserve(res.data));
    }
  }
  return null;
};

export const fetchFinancialsPagamenti = (sinistroId) => async (dispatch) => {
  if (sinistroId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/financials/listaMovimenti/${sinistroId}/?filter=PAGAMENTO`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res', res);
    console.log('err', err);
    if(res){
      return dispatch(setFinancialsPagamenti(res.data));
    }
  }
  return null;
};


//CHIAMTA TUTTE LE FETCH
export const fetchTotaliFinancials = (idSinistro, datiForm) => async (dispatch) => {   
  dispatch(setLoader(true));
  dispatch(fetchTotaleRiservaLorda(idSinistro, datiForm));
  dispatch(fetchTotaleRiservNetta(idSinistro, datiForm));
  dispatch(fetchTotatalePagamenti(idSinistro, datiForm));
  dispatch(fetchTotataleRiservaResidua(idSinistro, datiForm));
  dispatch(fetchTotataleRiservaAttiva(idSinistro, datiForm));
  dispatch(fetchTotaleRecuperi(idSinistro, datiForm));
  dispatch(fetchTotaleRiservaAttivaResidua(idSinistro, datiForm));
  dispatch(fetchTotaleFranchigia(idSinistro, datiForm));
  dispatch(fetchFinancialsTotaleMovimenti(idSinistro));
  dispatch(fetchFinancialsRiserve(idSinistro));
  dispatch(fetchFinancialsPagamenti(idSinistro));
};
 

export default FinancialsSlice.reducer;
